body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
}

#root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.app-layout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(50, 49, 48);

  .container {
    width: 84%;
    margin: auto;
    padding: 40px 0;
    box-sizing: border-box;
  }
}

.app-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.qr-column {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  button {
    max-width: 300px;
  }
}